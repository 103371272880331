<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style lang="scss">
* {
	margin: 0;
	padding: 0;
}
#app {
	width: 100%;
  font-size: 14px;
}
</style>
