import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

const initRoutes = [
    {
        path: '/',
        redirect: "/index",
        component: () => import('@/views/home/homeCp.vue'),

    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/home/homeCp.vue'),
        meta: {
            title: 'ホームページ',
            needLogin: false
        },
    },
    {
        path: '/introduce',
        name: 'introduce',
        component: () => import('@/views/home/introduce.vue'),
        meta: {
            title: '業務フロー',
            needLogin: false
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login.vue'),
        meta: {
            title: 'ログイン',
            needLogin: false
        }
    },
    {
        path: '/forgotpass',
        name: 'forgotpass',
        component: () => import('@/views/forgotpass/index.vue'),
        meta: {
            title: 'パスワード忘れ',
            needLogin: false
        }
    },
    {
        path: '/sign',
        name: 'sign',
        component: () => import('@/views/login/sign.vue'),
        meta: {
            title: 'ユーザー登録',
            needLogin: false
        }
    },
    {
        path: '/personal-center',
        name: 'personalCenter',
        component: () => import('@/views/personalCennter/index.vue'),
        redirect: '/personal-center/personal-index',
        meta: {
            title: 'マイページ',
            needLogin: false
        },
        children: [
            {
                path: "personal-index",
                name: "personalIndex",
                component: () => import('@/views/personalCennter/personalIndex/index.vue'),
                redirect: '/personal-center/personal-index/personal-show',
                meta: {
                    title: 'マイページ',
                    needLogin: false
                },
                children:[
                    {
                        path: 'personal-show',
                        name: 'personalShow',
                        component:() => import('../views/personalCennter/personalIndex/personalIndex.vue'),
                        meta: {
                            title:'マイページ',
                            needLogin: false
                        }
                    },
                    {
                        path: 'creat-project',
                        name: 'creatProject',
                        component:() => import('../views/personalCennter/personalIndex/creatProject.vue'),
                        meta: {
                            title: '新規プロジェクト登録',
                            needLogin: false
                        }
                    }
                ]
            },
            {
                path: "friend-manage",
                name: "friendManage",
                component: () => import('../views/personalCennter/components/friendManage.vue'),
                meta: {
                    title: '友達リスト',
                    needLogin: false
                }
            },
            {
                path: "personal-data",
                name: "personalData",
                component: () => import('../views/personalCennter/components/personalData.vue'),
                meta: {
                    title: '個人情報',
                    needLogin: false
                }
            },
            {
                path: "personal-project-search",
                name: "personalProjectSearch",
                component: () => import('../views/personalCennter/components/projectSearch.vue'),
                meta: {
                    title: 'プロジェクト検索',
                    needLogin: false
                }
            },
            {
                path: "personal-project-manage",
                name: "personalProjectManage",
                component: () => import('../views/personalCennter/components/projectManage.vue'),
                meta: {
                    title: 'プロジェクト管理',
                    needLogin: false
                }
            },
            {
                path: "personal-project-ajsearch",
                name: "personalProjectAjSearch",
                component: () => import('../views/personalCennter/components/anjiansearch.vue'),
                meta: {
                    title: '案件検索',
                    needLogin: false
                }
            },
            {
                path: "personal-project",
                name: "personalProject",
                component: () => import('../views/personalCennter/personalproject/index.vue'),
                redirect: '/personal-center/personal-project/my-project',
                meta: {
                    title: '個人情報',
                    needLogin: false
                },
                children:[
                    {
                        path: 'my-project',
                        name: 'myProject',
                        component:() => import('../views/personalCennter/personalproject/personalProject.vue'),
                        meta: {
                            title: '私のプロジェクト',
                            needLogin: false
                        }
                    },
                    {
                        path: 'creat-case',
                        name: 'creatCase',
                        component:() => import('../views/personalCennter/personalproject/creatCase.vue'),
                        meta: {
                            title: '新規案件登録',
                            needLogin: false
                        }
                    },
                    {
                        path: 'project-details',
                        name: 'projectDetails',
                        component: () => import('../views/personalCennter/personalproject/projectDetails.vue'),
                        meta: {
                            title:'プロジェクト詳細',
                            needLogin: false
                        }
                    },
                    {
                        path: 'cart-details',
                        name: 'carttDetails',
                        component: () => import('../views/personalCennter/personalproject/cartDetails.vue'),
                        meta: {
                            title:'案件詳細',
                            needLogin: false
                        }
                    },
                    {
                        path: 'proces-setting',
                        name: 'procesSetting',
                        component: () => import('../views/personalCennter/personalproject/processSetting.vue'),
                        meta: {
                            title:'業務フロー設定',
                            needLogin: false
                        }
                    },
                    {
                        path: 'proces-Rout',
                        name: 'procesRout',
                        component: () => import('../views/personalCennter/personalproject/processRout.vue'),
                        meta: {
                            title:'ネーム',
                            needLogin: false
                        }
                    },
                    {
                        path: 'case-list',
                        name: 'caseList',
                        component: () => import('../views/personalCennter/personalproject/caseList'),
                        meta: {
                            title:'参加した案件',
                            needLogin: false
                        }
                    },
                ]
            },
            {
                path: "profit-manage",
                name: "profitManage",
                component: () => import('../views/personalCennter/components/profitManage.vue'),
                meta: {
                    title: '収益管理',
                    needLogin: false
                }
            },
            {
                path: "search-original",
                name: "searchOriginal",
                component: () => import('../views/personalCennter/components/searchOriginal.vue'),
                meta: {
                    title: '原作者検索',
                    needLogin: false
                }
            }
        ]
    }
]



const router = new VueRouter({
    mode: 'hash',
    routes: initRoutes
})

router.beforeEach((to, from, next) => {
    if(to.meta.title) {
        document.title = to.meta.title
    }

    if(to.meta.needLogin) {
        let token = window.localStorage.getItem("token");
        if(token) {
            next()
        } else {
            next({
                path: '/login'
            })
        }
    } else {
        next()
    }
})
export default router